/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.home{
  background-color: #fff;
}

.home_tit {
  height: 80px;
  background-image: linear-gradient(134deg, #81eedf 0%, #8fccf3 100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 20px 0;
}
.home_tit .head {
  width: 76%;
  height: 100%;
  margin: 0 auto;
}
.home_tit .head .tit {
  width: 20%;
  height: 20px;
  z-index: 1000;
  margin: 10px 0 0;
  float: left;
}
.home_tit .head .nav {
  float: right;
  width: 80%;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  z-index: 1000;
}
.home_tit .head .nav .link {
  float: right;
  width: 77%;
}
.home_tit .head .nav a:hover {
  color: #20b7bc;
}
.home_tit .head .nav .active {
  color: #20b7bc;
}
.home_tit .head .nav .btn {
  display: inline-block;
  width: 96px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 21px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #4fd2cd;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin: 0 0 0 6%;
  float: right;
}
.home_tit .head .nav .btn:hover {
  background-color: #20b7bc;
  color: #fff;
}
.home_head {
  height: 647px;
  background-image: linear-gradient(134deg, #81eedf 0%, #8fccf3 100%);
  position: relative;
}
.home_head .head_content {
  width: 77%;
  height: 100%;
  margin: 0 auto;
  padding: 160px 0 0;
}
.home_head .head_content img {
  width: 33%;
  height: 80%;
  float: left;
  margin-left: 6%;
}
.home_head .head_content .content {
  float: right;
  width: 47%;
  height: 163px;
  color: #fff;
  margin-top: 90px;
}
.home_head .head_content .content .title {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 2px;
}
.home_head .head_content .content .title span {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  margin-left: 10px;
}
.home_head .head_content .content p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  margin: 15px 0 0;
}
.home_head .head_content .content .btn {
  height: 42px;
  background-color: rgba(255, 255, 255, 0.17);
  box-shadow: 0px 6px 15px 0px rgba(118, 201, 234, 0.3);
  border-radius: 21px;
  padding: 0 20px;
  color: #fff;
  font-size: 14px;
  line-height: 42px;
  margin-top: 61px;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 24px;
}
.home_head .cicle1 {
  width: 166px;
  height: 166px;
  background-color: #ffffff;
  opacity: 0.25;
  border-radius: 50%;
  position: absolute;
  left: 439px;
  bottom: 40px;
}
.home_head .cicle2 {
  width: 96px;
  height: 96px;
  background-color: #ffffff;
  opacity: 0.15;
  border-radius: 50%;
  position: absolute;
  left: 983px;
  top: 254px;
}
.home_head .cicle3 {
  width: 43px;
  height: 43px;
  background-color: #ffffff;
  opacity: 0.08;
  border-radius: 50%;
  position: absolute;
  right: 516px;
  bottom: 179px;
}
.home_banner {
  width: 100%;
  height: 594px;
  padding: 83px 0 30px;
  box-sizing: border-box;
}
.home_banner .title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 2px;
  color: #111414;
  margin: 0;
}
.home_banner .con {
  width: 55%;
  height: 100%;
  margin: 0 auto;
}
.home_banner .con img {
  width: 36%;
  height: 280px;
  margin: 90px 0 0;
  float: right;
}
.home_banner .con .content {
  float: left;
  width: 35%;
  height: 116px;
  margin: 172px 0 0;
}
.home_banner .con .content h4 {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #111414;
  margin: 0 0 30px;
}
.home_banner .con .content p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: 0px;
  color: #656b6b;
  margin: 0;
}
.home_app {
  width: 100%;
  height: 616px;
  padding: 83px 0 30px;
  box-sizing: border-box;
  background-image: linear-gradient(134deg, #81eedf 0%, #8fccf3 100%);
}
.home_app .title {
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 2px;
  color: #ffffff;
}
.home_app .con {
  width: 68%;
  height: 100%;
  margin: 0 auto;
}
.home_app .con .img {
  width: 50%;
  height: 380px;
  float: left;
  margin: 0 0 0 0;
  vertical-align: top;
}
.home_app .con .content {
  width: 45%;
  height: 250px;
  float: right;
  margin: 46px 0 0 0;
  padding-left: 130px;
  padding-top: 10px;
}
.home_app .con .content .qrcode {
  width: 180px;
  height: 180px;
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-block;
  vertical-align: top;
}
.home_app .con .content .qrcode img {
  width: 160px;
  height: 160px;
  background-color: #f5f8f8;
  display: inline-block;
  margin: 10px;
  vertical-align: middle;
}
.home_app .con .content h4 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 1px;
  color: #ffffff;
}
.home_app .con .content .btns {
  width: 142px;
  height: 114px;
  display: inline-block;
  margin: 73px 0 0;
}
.home_app .con .content .btns .btn {
  width: 142px;
  height: 42px;
  background-color: #ffffff;
  box-shadow: 0px 6px 15px 0px rgba(118, 201, 234, 0.3);
  border-radius: 21px;
}
.home_app .con .content .btns .btn img {
  width: 20px;
  height: 23px;
  display: inline-block;
  margin: 0px 10px 10px 20px;
  vertical-align: middle;
}
.home_app .con .content .btns .btn span {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4fd2cd;
  line-height: 42px;
}
.home_features {
  width: 100%;
  height: 866px;
  background-color: #ffffff;
  padding: 83px 0 100px;
}
.home_features .title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 2px;
  color: #111414;
  margin: 0;
}
.home_features .content {
  width: 1140px;
  margin: 100px auto 0;
}
.home_features .content .item {
  display: inline-block;
  margin-right: 105px;
}
.home_features .content .item img {
  display: block;
  height: 123px;
  margin: 0 auto;
}
.home_features .content .item h4 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #111414;
  margin: 30px 0 25px;
  text-align: center;
}
.home_features .content .item p {
  display: block;
  width: 310px;
  height: 40px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #656b6b;
  text-align: center;
  margin: 0;
}
.home_features .content :last-child.item {
  margin-right: 0;
}
.count {
  width: 100%;
  height: 110px;
  background-image: linear-gradient(134deg, #81eedf 0%, #8fccf3 100%);
  padding: 33px 0 24px;
}
.count .list {
  width: 986px;
  margin: 0 auto;
}
.count .list .item {
  display: inline-block;
  margin-right: 220px;
}
.count .list .item div {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  position: relative;
  text-align: center;
}
.count .list .item div span {
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 16px;
  width: 10px;
  height: 16px;
}
.count .list .item p {
  text-align: center;
  margin: 15px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #ffffff;
}
.count .list :last-child.item {
  margin: 0;
}
.about {
  width: 100%;
  background-color: #ffffff;
  padding: 83px 0 0;
}
.about .title {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 2px;
  color: #111414;
  text-align: center;
  margin-bottom: 90px;
}
.about .content {
  margin: 0 auto 50px;
  width: 1200px;
}
.about .content .item {
  display: inline-block;
  position: relative;
  width: 575px;
  cursor: pointer;
}
.about .content .item img {
  width: 575px;
  height: 300px;
}
.about .content .item h4 {
  position: absolute;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 0px;
  color: #ffffff;
  top: 135px;
  left: 215px;
}
@keyframes tab-line-active-animation {
  0% {
    left: 575px;
    right: 0;
  }
  100% {
    left: 288px;
    right: 0;
  }
}
.about .content .item .detail {
  width: 287px;
  display: none;
  height: 300px;
  background-color: #f6f8f8;
  padding: 76px 80px 0;
  z-index: 99;
  position: absolute;
  right: 0px;
  left: 288px;
  top: 0;
}
.about .content .item .detail h5 {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #181818;
  margin: 0 0 15px;
}
.about .content .item .detail p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #575759;
  margin: 0;
}
.about .content .item:hover .detail {
  display: block;
}
.about .content :first-child.item {
  margin-right: 50px;
}
.bottom {
  width: 100%;
  height: 169px;
  background-color: #f6f8f8;
  padding: 30px 0;
}
.footer {
  width: 100%;
  height: 100px;
  background-color: #fff;
  text-align: center;
  padding-top: 50px;
  color: #3c3c3c;
}

.link {
  text-decoration:none;
  font-size: 14px;
  color: #3c3c3c;
}

.link:hover{
  color:#3c3c3c;
}

.bottom .con {
  margin: 0 auto;
  width: 66%;
  height: 100%;
}
.bottom .left {
  margin: 32px 0 0 0;
  vertical-align: top;
  float: left;
}
.bottom .left p {
  margin: 0 0 15px;
}
.bottom .right {
  float: right;
}
.bottom .right span {
  display: block;
  width: 80px;
  text-align: center;
  margin-top: 10px;
}
.bottom .img_box {
  width: 80px;
  height: 80px;
  padding: 5px;
  background: #fff;
}
.bottom .img_box img {
  display: block;
  width: 100%;
  height: 100%;
}
.link_item {
  margin: 0 0 0 8%;
  line-height: 40px;
  float: right;
  padding: 0;
  color: #fff !important;
}
.link_item > .ant-anchor-link-title {
  -webkit-transition: all 0.3s;
  /* transition: all 0.3s; */
  color: #fff !important;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  margin-bottom: 6px;
  font-size: 16px;
}

.link_item > .ant-anchor-link-title-active {
  -webkit-transition: all 0.3s;
  /* transition: all 0.3s; */
  color: #4fd2cd !important;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  margin-bottom: 6px;
  font-size: 16px;
}
.ant-anchor-ink-ball.visible {
  display: none; 
}
.ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  background-color: #4fd2cd;
  opacity: 0.1;
  border-radius: 50%;
}

.ant-carousel .slick-dots li.slick-active button{
  width: 10px;
  height: 10px;
  background-color: #4fd2cd;
  opacity: 1;
  border-radius: 50%;
}
.ant-carousel .slick-dots-bottom {
  bottom: 30px;
}